import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { AptosAPIInstance } from "../../apis/AptosAPI";
import WithdrawModal2FA from "./WithdrawModal2FA";
import {QRCodeSVG} from 'qrcode.react';

import "./index.css";


export default function Aptos() {
    const [account, setAccount] = useState(null);
    const [balance, setBalance] = useState(0);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const user = useSelector(selectCurrentUser);
    const [withdrawAddress, setWithdrawAddress] = useState("")
    const [withdrawAmount, setWithdrawAmount] = useState("")
    const [open2FAModal, setOpen2FAModal] = useState(false);
    const [tempToken, setTempToken] = useState(null);
    const [authQRImg, setAuthQRImg] = useState(null);
    const navigate = useNavigate()

    const modalProps = {
        tempToken,
        user: user,
        email: user?.email,
        receiverAddress: withdrawAddress,
        withdrawAmount: withdrawAmount,
    };

    const handleWithdraw = async (user) => {
        try {
            const res = await AptosAPIInstance.create2FAToken(user);
            if (res?.data?.message === "TWO_FACTOR_REQUIRED"){
                setTempToken(res?.data?.data?.token);
                setOpen2FAModal(true);
            }
            else{
                navigate("/settings")
            }

        } catch (error) {
            alert("Error withdrawing from wallet: Please try again.");
            console.error("Error withdraw wallet:", error);
        }
    };

    useEffect(() => {
        if (user?.userId) {
            fetchWalletInfo(user.userId);

        }
    }, [user]);

    useEffect(() => {   
        if (balance > 0) {
            //if no balance, no need to fetch transactions
            fetchTransactions(user.userId);
        }
    }, [balance]);

    const fetchTransactions = async (userId) => {
        try {
            setLoading(true);

            const depositResponse = await AptosAPIInstance.getDepositTransaction(userId);
            const withdrawResponse = await AptosAPIInstance.getWithdrawTransaction(userId);

            const depositTransactions = depositResponse.data.data.map((tx) => ({
                ...tx,
                transactionType: 'Deposit',
            }));
            
            const withdrawTransactions = withdrawResponse.data.data.map((tx) => ({
                ...tx,
                transactionType: 'Withdraw',
            }));

            const combinedTransactions = [...depositTransactions, ...withdrawTransactions];

            combinedTransactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setTransactions(combinedTransactions);

        } catch (error) {
            console.error("Error fetching transactions:", error);
        }
    };

    const handleCreateWallet = async (user) => {
        console.log("Creating wallet for user:", user);
        try {
            const createWallet = await AptosAPIInstance.createAptosWallet();
            console.log("Create wallet response:", createWallet);
            if (createWallet) {
                fetchWalletInfo(user.userId);
                //fetchTransactions(user.userId);
            }
        } catch (error) {
            console.error("Error creating wallet:", error);
        }
    };

    const fetchWalletInfo = async (userId) => {
        try {
            const response = await AptosAPIInstance.getWalletInfo(userId);
            console.log("Wallet info response:", response);
            if (response.data && response.data.data) {
                const { address, balance } = response.data.data;
                console.log("Wallet info balance :", address, balance);
                setAccount(address);
                setBalance(balance / 1e8);
            } else {
                console.error("Failed to fetch wallet info");
            }
        } catch (error) {
            console.error("Error fetching wallet information:", error);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = async (text) => {
        try {
        await navigator.clipboard.writeText(text);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
        } catch (error) {
        console.error("Failed to copy text: ", error);
        }
    };

    const hideModal = () => {
        setOpen2FAModal(false)
        setTempToken(null);
    }

    return (
        <>
        <Link to={process.env.PUBLIC_URL + "/"}>
            <div className="d-flex align-items-center justify-content-start px-5 py-4">
            <div className="d-flex align-items-center">
                <i className="fa fa-chevron-left me-3" />
                <p className="mb-0 ">Back</p>
            </div>
            </div>
        </Link>

        <div className="wallet-main-container m-5 mt-2">
            {loading ? (
            <p>Loading...</p>
            ) : account ? (
            <div className="d-flex flex-column gap-1 justify-content-center">
                {open2FAModal && <WithdrawModal2FA show={open2FAModal} onHide={hideModal} {...modalProps}  />}
                <h3>Wallet Overview</h3>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row gap-1 align-items-start">
                        <span style={{ fontWeight: "bold", fontSize: "1em" }}>
                            Balance:
                        </span>
                        <span>
                            {balance}
                        </span>
                    </div>
                    <div className="d-flex flex-row gap-2 align-items-center">
                        <span style={{ fontWeight: "bold", fontSize: "1em" }}>
                            Account:
                        </span>
                        <span>
                            {account}
                        </span>
                        <div
                            className="btn orange-gradient-btn "
                            onClick={() => copyToClipboard(account)}
                            >
                                <div className="d-flex flex-row gap-2 ml-3">
                                    <div className="d-flex flex-row gap-2 align-items-center">
                                    <i class="fa fa-clipboard" aria-hidden="true"></i>
                                    <span style={{ fontSize: "0.75rem" }}>
                                            {copied ? "Copied" : "Copy to Clipboard"}
                                        </span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="qr-code-container d-flex flex-column p-2 gap-1">
                    <QRCodeSVG value={account}/>
                </div>
                <div className="d-flex flex-column mt-4 gap-1">
                    <h3>Withdrawal</h3>
                    <span style={{ fontWeight: "bold", fontSize: "1em" }}>Amount</span>
                    <input
                        type="number"
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        placeholder="Withdrawal Amount"
                        className="withdraw-form-control"
                    />
                    <span style={{ fontWeight: "bold", fontSize: "1em", marginTop: "5px" }}>Destination Address</span>
                    <input
                        type="text"
                        value={withdrawAddress}
                        onChange={(e) => setWithdrawAddress(e.target.value)}
                        placeholder="Destination Address"
                        className="withdraw-form-control"
                    />
                    <div
                        className="btn orange-gradient-btn mt-2"
                        onClick={() => {
                            if (withdrawAddress && withdrawAmount) {
                                handleWithdraw(user, withdrawAddress, withdrawAmount);
                            } else {
                                alert("Please fill in both the withdrawal amount and address.");
                            }
                        }}
                        style={{width: '30%'}}
                        >
                            <div className="d-flex flex-row gap-2 justify-content-center">
                                <div className="d-flex flex-row gap-2 align-items-center">
                                <span style={{ fontSize: "1.2em" }}>
                                        Withdraw
                                </span>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="d-flex flex-column mt-4 gap-2">
                    <h3>Transaction History</h3>
                    <table className="withdrawal-table">
                        <thead>
                            <tr style={{ backgroundColor: "transparent" }}>
                                <th>Transaction Hash</th>
                                <th>Transaction Type</th>
                                <th>Timestamp</th>
                                <th>Sender</th>
                                <th>Receiver</th>
                                <th>Coin Type</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((tx) => (
                                <tr
                                key={tx.txHash}
                                >
                                <td>{tx.txHash.substring(0, 8)}...</td>
                                <td>
                                    <div className="transaction-type-container d-flex p-1 align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: tx.transactionType === 'Deposit' ? '#5cbc87' : '#e8635c',
                                        color: tx.transactionType === 'Deposit' ? '#106034' : '#660500',
                                        fontWeight: 'bold'
                                        }}
                                    >
                                        {tx.transactionType}
                                    </div>
                                </td>
                                
                                <td>{new Date(tx.createdAt * 1000).toLocaleString()}</td>
                                <td>{tx.senderAddress}</td>
                                <td>{tx.receiverAddress}</td>
                                <td>Aptos</td>
                                <td>
                                    <div className="transaction-type-container d-flex p-1 align-items-center justify-content-center"
                                    style={{
                                        color: tx.transactionType === 'Deposit' ? '#5cbc87' : '#ff7c7c',
                                        fontWeight: 'bold'
                                        }}
                                    >
                                    {tx.transactionType === 'Deposit' ? '+' : '-'}
                                    {tx.amount / 1e8}
                                    </div>
                                </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            ) : (
            <div
                style={{ width: "400px" }}
                className="referral-earning-card d-flex flex-row gap-5 p-3 justify-content-center"
                onClick={() => handleCreateWallet(user)}
            >
                <div className="d-flex flex-row gap-2">
                <div className="d-flex flex-row align-items-center">
                    <span style={{ fontSize: "1.05rem" }}>Create Wallet</span>
                </div>
                </div>
            </div>
            )}
        </div>
        </>
    );
}