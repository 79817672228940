import { generateRandomId } from "../../../../utils/id";
import { socketConstants } from "../../../../apis/SocketApis";

export const characterSocketRecieverHelper = (eventData, 
    selectedType, 
    setMessages, 
    setCharReplying, 
    user, 
    setCharData, 
    setSpicyAiSelected,
    chatId,
    setImgGenButtonDisabled = () => {},
    setInProgressImage
    ) => {

    const { CLIENT, SERVER } = socketConstants;
    //console.log("characterSocketRecieverHelper ?.type: ", eventData?.type);
    switch (eventData?.type) {
        case CLIENT.CUSTOM_AI.SEND_REPLIER_RESPONSE_TO_CLIENT:
          if (selectedType !== "client" && eventData?.customAIModelId == chatId) {
                    const messageId = generateRandomId();
                    const newMessage = {
                        sentByUser: false,
                        _id: messageId,
                        timeStamp: Math.ceil(new Date().getTime() / 1000),
                        messageContent: eventData.prompt,
                        originalMessage: "",
                        ...(eventData?.translation ? { translatedMessage: eventData?.translation, showTranslatedMessage: true } : {}),
                    };
                    setMessages((prev) => {
                        let temp = [...prev];
                        temp.unshift(newMessage);
                        return [...temp];
                    });
                    
                    if (!user?.chatTier) {
                        //getSuggestedCoupon(newMessage);
                    }
            }
            //set to false every time incoming message
            setCharReplying(false);
        break;
    
        case CLIENT.CUSTOM_AI.SERVER_MESSAGE_ACKNOWLEDGEMENT:
            setMessages((prev) => {
                const temp = [...prev];
                const index = temp.findIndex((message) => {
                    return message?._id === eventData?.messageId;
                });
                if (index !== -1) {
                    temp[index]._id = eventData?.payload?._id;
                }
                return [...temp];
            });
        break;
    
        case SERVER.CUSTOM_AI.STATUS.REPLYING:
            console.log("SERVER.CUSTOM_AI.STATUS.REPLYING: ");
            if (eventData.userId === user.userId && eventData?.customAIModelId === eventData?.character?._id) {
                setCharData({ isCharacterReady: false });
            }
        break;
    
        case SERVER.CUSTOM_AI.STATUS.READY:
            if (eventData.userId === user.userId && eventData?.customAIModelId === eventData?.character?._id) {
                setCharData({ isCharacterReady: true });
            }
        break;
        
        case SERVER.CUSTOM_AI.REGENERATE_RESPONSE:
            setMessages((prev) => {
                const temp = [...prev];
                const index = temp.findIndex((message) => {
                    return message?._id === eventData?.messageId;
                });
                if (index !== -1) {
                    temp[index].messageContent = eventData?.prompt;
                }
                return [...temp];
            });
        break;
        case SERVER.IMAGE_GEN.MINER_SEND_IMAGE_PROGRESS_TO_CLIENT:
            //from image generator replier
            console.log("MINER_SEND_IMAGE_PROGRESS_TO_CLIENT user.userId: ", user?.userId);
            //imageQueueID, result
                const userIdForImage =eventData?.imageQueueID?.current[0]?.sender_userId;
            if (selectedType !== "client" && userIdForImage==user.userId) {//eventData?.AiChat.userId==user.userId 
                //setImgGenButtonDisabled(true);
                const percentage = eventData?.result?.progress;
                const baseImage =  eventData?.result?.current_image;
                const imageQueueID = eventData?.imageQueueID?.current[0]?.sender_userId;
                console.log("baseImage64: ", baseImage);
                console.log("eventData?.result?.imageQueueID?.sender_userId: ", eventData?.imageQueueID?.current[0]?.sender_userId);
                setInProgressImage({"percent":percentage, "current_image":baseImage });
                //console.log("MINER_SEND_IMAGE_PROGRESS_TO_CLIENT eventData: ", eventData.result.current_image);
            }
        break;
        case SERVER.IMAGE_GEN.FINISHED_IMAGE_SENT_BACK_TO_USER:
            //from image generator replier
            console.log("FINISHED_IMAGE_SENT_BACK_TO_USER: ", eventData);
            if (selectedType !== "client" && eventData?.AiChat.customAIModelId == chatId && eventData?.AiChat.userId==user.userId) {
                const messageId = generateRandomId();
                const newMessage = {
                    sentByUser: false,
                    _id: messageId,
                    imageUrl: eventData.AiChat?.imageUrl,
                    timeStamp: Math.ceil(new Date().getTime() / 1000),
                    messageContent: eventData.AiChat.prompt,
                    originalMessage: "",
                    ...(eventData.AiChat?.translation ? { translatedMessage: eventData.AiChat?.translation, showTranslatedMessage: true } : {}),
                };
                setMessages((prev) => {
                    let temp = [...prev];
                    temp.unshift(newMessage);
                    return [...temp];
                });
                setImgGenButtonDisabled(false);
                alert("Image Generated Successfully!");
            }
        break;

        case  SERVER.CUSTOM_AI.GET_ALL_AVAILABLE_REPLIERS:
            console.log("SERVER.CUSTOM_AI.GET_ALL_AVAILABLE_REPLIERS: ", eventData);
            //setSpicyAiSelected("number of replier: "+eventData?.data?.[0]?.username+", ai:"+eventData?.data?.[0]?.aiModelInfo);
            setSpicyAiSelected(eventData?.data);
        break;

        case 'ALERT':
            console.log("ALERT", eventData);
            //setSpicyAiSelected("number of replier: "+eventData?.data?.[0]?.username+", ai:"+eventData?.data?.[0]?.aiModelInfo);
            alert(eventData?.message);
        break;

    
    }//end switch
}