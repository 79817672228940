/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMessagesOfChat } from "../../../Redux/Reducers/ChatReducer";
import { MessageComments } from "../../../Screen/AICharacterScreen/components/Comments";
import { AIApiInstance } from "../../../apis/AIAPI";
import { authAPIInstance } from "../../../apis/AuthAPI";
import { ClientCharAPIInstance } from "../../../apis/ClientCharApi";
import { PointRewardApiInstance } from "../../../apis/PointRewardApi";
import { socketConstants } from "../../../apis/SocketApis";
import { NewSocketService } from "../../../apis/SocketApis/NewSocketService";
import { readCookie } from "../../../utils/cookie";
import { removeChatPrefix, removeYouPrefix } from "../../../utils/customCharacter";
import { eventActions, eventCategories, sendFormActionEvent } from '../../../utils/googleAnalytics';
import { generateRandomId } from "../../../utils/id";
import { getAiCharacterImageUrl } from "../../../utils/images";
import Avatar from "../../Comman/Avatar";
import { StatusBadge } from "../../Comman/StatusBadge";
import GoogleAd from "../../GoogleAd/GoogleAd";
import { Modal } from '../../Modal/Modal';
import RewardAdBox from "../../RewardAdBoxes/RewardAdBox";
import { ChatItem } from "./ChatItem";
import { CouponAd } from "./CouponAd";
import { characterSocketRecieverHelper } from './Helpers/CharacterSocketRecieverHelper';
import { AutoTranslateModal, SelectSpicyAiModal } from './Popup/CharChatBoxModals';
import PublishChatBody from "./PublishChatBody";
import "./chatbox.css";

let checkLastMessageIsSentInterval = null;

const initialStates = {
    avatar: "",
    chatName: "",
    message: "",
    character: null,
    isChatHistoryFetching: false,
    isCharacterReady: true,
    selectedChatIndex: null
};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "50vw",
        height: "70vh",
        maxHeight: "80vh",
        backgroundColor: "#883bd0",
    }
};

function ClientChatBox({user, chatId, toggleDrawer, status, selectedType, selectedUserId = "" }) {
    
    let { sendMessage, lastMessage, readyState, getWebSocket } = NewSocketService("CharacterChatBox");
    let ws = getWebSocket();
    const [currentChar, setCharData] = useReducer((prev, next) => {
        return { ...prev, ...next };
    }, initialStates);
    const [messages, setMessages] = useState([]);
    const [websocket, setWebSocket] = useState(null);
    const [isChatPublic, setIsChatPublic] = useState(false);
    const [askPublishChat, setAskPublishChat] = useState({
        response: null,
        showModal: false
    });
    const [charReplying, setCharReplying] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);
    const [showRewardAdModal, setShowRewardAdModal] = useState(false);
    const [autoTranslate, setaAutoTranslate] = useState(true);
    const [translateModal, setTranslateModal] = useState(false);
    const [selectSpicyAiPopup, setSelectSpicyAiPopup] = useState(false);
    const [spicyAiModels, setSpicyAiModels] = useState([]);
    const [selectedSpicyAiModel, setSelectedSpicyAiModel] = useState(null);
    const [rolePlay , setRolePlay ] = useState(false);
    const [rolePlayModal, setRolePlayModal] = useState(false);
    const [subscription, setSubscription] = useState({ price: 0, subscription_status: false, expired: false });
    const autoTranslateDisabled = useMemo(() => {
        return user?.spicyChatEnabled;
    }, [user?.spicyChatEnabled]);
    const chatDisabledSubscription = useMemo(() => {
        if(subscription?.price > 0){
            return subscription.expired || !subscription.subscription_status && selectedType!== 'client';
        } else {
            return false;
        }
    }, [subscription]);

    const token = readCookie('token');

    const attachmentFileRef = useRef(null);

    const dispatch = useDispatch();
    const bottomRef = useRef(null);
    const navigate = useNavigate();

    const toggleAskPublicModal = (boolValue) => {
        setAskPublishChat((prev) => {
            return { ...prev, showModal: boolValue };
        });
    };

    const toggleTranslateModal = (boolValue) => {
        console.log("toggleTranslateModal");
        setTranslateModal(boolValue);
    };

    const toggleSelectSpicyAiPopup = (boolValue) => {
        console.log("toggleSelectSpicyAiPopup");
        setSelectSpicyAiPopup(boolValue);
    };

    const toggleRolePlayModal = (boolValue) => {
        console.log("RolePlayModal");
        setRolePlayModal(boolValue);
    };

    const handleDeleteChat = () => {
       const confirm =  window.confirm('Are you sure you want to delete all messages?');
       if(confirm && chatId){
        AIApiInstance.deleteCharacterChat(chatId)
        .then(_ => {
            alert('Messages deleted successfully.');
            setMessages([]);
        })
        .catch(console.error)
       }
    };

    const respondAskPublicModal = (boolValue) => {
        setAskPublishChat((prev) => {
            return { ...prev, response: boolValue };
        });
    };

    const resetAskPublicModal = () => {
        setAskPublishChat((prev) => {
            return { ...prev, response: null };
        });
    };

    const getCharacterChatHistory = useCallback(async () => {
        if (!chatId || chatId === "undefined") {
            return;
        }
        const { messages, aiCharacter } = (await AIApiInstance.getCharacterChatHistory(chatId, 1))?.data?.data;
        const chatHistory = messages.map((message) => {
            return { ...message, originalMessage: "" };
        });
        let avatarUrl =  '';
        if(Array.isArray(aiCharacter?.avatarUrls) && aiCharacter?.avatarUrls?.[0]){
            avatarUrl = aiCharacter?.avatarUrls[0]
        } else {
            avatarUrl = aiCharacter?.avatarUrl;
        }
        setCharData({
            avatar: avatarUrl,
            chatName: aiCharacter?.characterName,
            character: aiCharacter,
            isChatHistoryFetching: false
        });
        setMessages([...chatHistory]);
        dispatch(
            setMessagesOfChat({
                recipient_id: chatId,
                messages: chatHistory
            })
        );
    }, [chatId]);

    const getClientChatHistory = useCallback(async () => {
        if (!chatId || chatId === "undefined") {
            return;
        }
        const { messages, aiCharacter } = (await ClientCharAPIInstance.getClientCharacterChatHistory(selectedUserId, chatId, 1))?.data?.data;
        const chatHistory = messages.map((message) => {
            return { ...message, originalMessage: "" };
        });
        let avatarUrl =  '';
        if(Array.isArray(aiCharacter?.avatarUrls) && aiCharacter?.avatarUrls?.[0]){
            avatarUrl = aiCharacter?.avatarUrls[0]
        } else {
            avatarUrl = aiCharacter?.avatarUrl;
        }
        setCharData({
            avatar: avatarUrl,
            chatName: aiCharacter.characterName,
            character: aiCharacter,
            isChatHistoryFetching: false
        });
        setMessages([...chatHistory]);
        dispatch(
            setMessagesOfChat({
                recipient_id: chatId,
                messages: chatHistory
            })
        );
    }, [chatId, selectedUserId]);

    const buildPrompt = useCallback(
        (chatHistory) => {
            const { character } = currentChar;

            const concatLastTenMessages = (messages) => {
                const temp = [...messages];
                return temp.reduce(
                    (prev, current) =>
                        prev +
                        (current.sentByUser ? "You:" : `${currentChar.character?.characterName}:`) +
                        removeYouPrefix(current.messageContent) +
                        "\n",
                    ""
                );
            };

            const prompt = `[Character: ${character?.characterName}; species: Human; age 24; gender: ${
                character?.gender
            }; physical appearance: ${character?.physicalAppearance}; likes: ${character?.likes}; description: ${
                character?.description
            }]\n[The following is an interesting chat message and roleplay log between You and ${character?.characterName}.]\n\n${
                character?.characterName
            }:${removeChatPrefix(character?.firstMessage)}\n`;

            return prompt + concatLastTenMessages(chatHistory || messages);
        },
        [currentChar.character]
    );

    const renderChats = useCallback(
        () =>
            messages
                .sort((a, b) => a.timeStamp - b.timeStamp)
                .map((message, i) => (
                    //console.log("imageUrl: ",message?.imageUrl),
                    <div key={`chat-item-${i}`}>
                        {message?.type === "ad" ? (
                            <CouponAd adData={message?.adData} />
                        ) : (
                            <ChatItem
                                avatar={currentChar.avatar}
                                chatName={currentChar.chatName}
                                message={message}
                                index={i}
                                onSelect={(index) => setCharData({ selectedChatIndex: index })}
                                onUpdateMessage={handleUpdateMessage}
                                onRegenerateMessage={() => handleRegenerateMessage(i, message)}
                                chatId={chatId}
                                currentCharacter={currentChar?.character}
                            />
                        )}
                    </div>
                )),
        [messages]
    );

    const handleSendMessage = async () => {
        const { message, character } = currentChar;
        const messageId = generateRandomId();
        setMessages((prev) => {
            let temp = [...prev];
            temp.unshift({
                sentByUser: selectedType === "client" ? false : true,
                _id: messageId,
                timeStamp: Math.floor(new Date().getTime() / 1000),
                messageContent: message,
                originalMessage: ""
            });
            return [...temp];
        });
        setCharData({ message: "" });
        if(selectedType === "client"){
            sendMessageToUserAsAI(message, messageId);
        } else {
            sendMessageToAI(message, messageId, character);
        }
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.chat_client_event, message);
    };

    const sendMessageToAI = (message, messageId, character) => {
        setCharReplying(true);
        if (user?.spicyChatEnabled) {
            const stringPrompt = buildPrompt(messages);
            const newPrompt = `${stringPrompt}You: ${message}\n${character?.characterName}:`;
            const messageData = {
                type: socketConstants.CLIENT.CUSTOM_AI.MESSAGE,
                prompt: newPrompt,
                message,
                customAIModelId: chatId,
                device: "mobile",
                messageId: messageId,
                autoTranslate,
                isChatPublic
            };
            if(readyState === 1) {
                console.log("ws.readyState: ",readyState);
                ws.send(JSON.stringify(messageData));
            } else {
                console.log("ws.readyState: ", readyState); 
                alert("Check your internet connection.");
            } 
        } else {
            console.log('isChatPublic', isChatPublic)
            AIApiInstance.sendCharacterMessage(chatId, message, user?.userLanguageCode, autoTranslate, rolePlay, isChatPublic)
            .then(res => {
                console.log('reply received from character');
                setCharReplying(false);
                setTimeout(() => {
                    setMessages((prev) => {
                        let temp = [...prev];
                        temp.unshift({
                            ...res?.data?.data?.message,
                            messageContent: res?.data?.data?.message?.messageContent?.encrypted_text,
                            ...(res?.data?.data?.message?.translatedMessage
                                ? { translatedMessage: res?.data?.data?.message?.translatedMessage }
                                : {}),
                            ...(res?.data?.data?.message?.showTranslatedMessage
                                ? { showTranslatedMessage: res?.data?.data?.message?.showTranslatedMessage }
                                : {})
                        });
                        return [...temp];
                    });
                }, 500);
            }).catch(console.error);
        }
    }

    const sendMessageToUserAsAI = (message, messageId) => {
        const messageData = {
            type: socketConstants.CLIENT.CUSTOM_AI.RESPONSE_FROM_REPLIER,
            targetUserId: messages[messages.length - 1]?.userId,
            response: message,
            customAIModelId: chatId,
            message_id: messageId,
            originalMessageId: ""
        };
        ws.send(JSON.stringify(messageData));
    }

    const handleUpdateSettingsClick = () => {
        navigate("/character-settings", {
            state: {
                aiCharacterId: chatId
            }
        });
    };

    const handleClickPublishChat = () => {
        toggleAskPublicModal(true);
    };

    const handleOnChangeTextArea = (event) => {
        setCharData({ message: event.target.value });
    };

    const handleAvatarClick = () => {
        navigate(`/character/${chatId}`, { state: { character: currentChar?.character } });
    };

    const handleUpdateMessage = useCallback(
        (messageId, newMessage) => {
            const index = messages.findIndex((message) => message._id === messageId);
            if (index !== -1) {
                const newMessages = [...messages];
                newMessages[index].messageContent = newMessage;
                setMessages(newMessages);
            }
        },
        [messages]
    );

    const handleRegenerateMessage = useCallback(
        (index, message) => {
            if (messages.length > 0) {
                // check spicy mode here
                if(user?.spicyChatEnabled){
                    const stringPrompt = buildPrompt(messages.slice(0, index));
                    const newPrompt = `${stringPrompt}${currentChar?.character?.characterName}:`;
                    const messageData = {
                        type: socketConstants.CLIENT.CUSTOM_AI.REGENERATE_RESPONSE,
                        prompt: newPrompt,
                        message: message.messageContent,
                        customAIModelId: chatId,
                        device: "mobile",
                        messageId: message._id
                    };
                    ws.send(JSON.stringify(messageData));
                } else {
                  // send for non spicy mode here
                  AIApiInstance.regenerateAIMessage(chatId, message._id, autoTranslate, rolePlay, isChatPublic).then(res => {
                    console.log(res)
                    setMessages((prev) => {
                        const temp = [...prev];
                        const index = temp.findIndex((message) => {
                            return message?._id === res?.data?.data?.message?._id;
                        });
                        if (index !== -1) {
                            temp[index].messageContent = res?.data?.data?.message?.messageContent?.encrypted_text;
                        }
                        return [...temp];
                    });
                  }).catch(console.error)
                }
            }
        },
        [messages, currentChar?.character]
    );

    const checkLastMessageIsSent = useCallback(() => {
        try {
            const lastMessage = messages[messages.length - 1];
            const elapsed = new Date().getTime() - lastMessage.timeStamp * 1000;
            const threshold = 5 * 60 * 1000; // 5 minutes;
            if (elapsed > threshold) {
                setCharData({ isCharacterReady: true });
                clearInterval(checkLastMessageIsSentInterval);
            }
        } catch (error) {
            console.error(error);
        }
    }, [currentChar.isCharacterReady, messages]);

    const getSuggestedCoupon = useCallback(
        async (recentMessage) => {
            const suggestedCouponResponse = await AIApiInstance.getSuggestedCoupon(
                recentMessage,
                user.userId,
                currentChar?.character._id
            );
            const { suggestedCoupon } = suggestedCouponResponse.data?.data;
            if (suggestedCoupon) {
                setMessages((prev) => {
                    let temp = [...prev];
                    temp.unshift({
                        sentByUser: false,
                        _id: Math.random().toString(),
                        timeStamp: Math.ceil(new Date().getTime() / 1000),
                        messageContent: "",
                        originalMessage: "",
                        type: "ad",
                        adData: suggestedCoupon
                    });
                    return [...temp];
                });
            }
        },
        [currentChar?.character, user?.userId]
    );

    useEffect(() => {
        const _autoTranslate = user?.userLanguageCode !== "en" ? true : false
        setaAutoTranslate(_autoTranslate);
    }, [user]);

    useEffect(() => {
        if (!ws || !currentChar.character) {
            return;
        }
        if (readyState === 1) {
            ws.send(
                JSON.stringify({
                    type: socketConstants.CLIENT.CUSTOM_AI.SUBSCRIBE,
                    customAIModelId: currentChar.character._id
                })
            );
        }  

    }, [chatId, ws, currentChar.character]);
    //Do not use  useEffect : getWebSocket() needs to be outside of useEffect for recconecting after server down.
    if(ws) { 
        ws.onmessage = (event) => {
            handleIncomingChar(event.data, chatId);
        };
    }

    const handleIncomingChar = (data, chatId ) => {
        let _data = JSON.parse(data);
            const eventData = _data;
            //characterSocketRecieverHelper is a helper function to handle all the events from socket.
            characterSocketRecieverHelper(eventData, 
                selectedType, 
                setMessages, 
                setCharReplying, 
                user, 
                setCharData, 
                setSpicyAiModels,
                chatId);
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages, currentChar.message, currentChar.isCharacterReady]);

    useEffect(() => {
        //todo check if client-char chats or just user's ai character
        if (selectedType === "client") {
            getClientChatHistory();
        } else { //normal ai character
            getCharacterChatHistory();
        }
    }, [selectedType, selectedUserId, chatId]);

    useEffect(() => {
        if (askPublishChat.response) {
            AIApiInstance.publishCharacterChats(currentChar?.character?._id, !isChatPublic)
                .then((_) => {
                    alert(`Chat is now ${isChatPublic ? "Private" : "Public"}.`);
                    setIsChatPublic((prev) => !prev);
                })
                .catch((e) => {
                    console.error(e);
                    alert(e?.response?.data?.message ?? "Something went wrong.");
                });
        }
        toggleAskPublicModal(false);
        resetAskPublicModal();
    }, [askPublishChat.response]);

    useEffect(() => {
        if (isChatPublic === null && messages.length > 0) {
            const isPublic = messages.find((msg) => {
              return msg.makeChatPublic === true
            });
            if (isPublic) {
                setIsChatPublic(true);
                return;
            }
            setIsChatPublic(false);
        }
        //  else {
        //     if (messages[0]?.makeChatPublic) {
        //         setIsChatPublic(true);
        //     } else {
        //         setIsChatPublic(false);
        //     }
        // }
    }, [messages, isChatPublic]);

    useEffect(() => {
        if (messages.length === 0) {
            return;
        }
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.sentByUser && !currentChar.isCharacterReady) {
            checkLastMessageIsSentInterval = setInterval(checkLastMessageIsSent, 1000);
        }
        return () => {
            if (checkLastMessageIsSentInterval) {
                clearInterval(checkLastMessageIsSentInterval);
            }
        };
    }, [checkLastMessageIsSent]);

    useEffect(() => {
        if (selectedType === "ai") {
            AIApiInstance.getCharacterSubscriptionStatus(chatId)
                .then((res) => {
                    setSubscription(res.data.data);
                })
                .catch((err) => {
                    console.error(err);
                })
        }
    }, [selectedType, chatId]);

    const { loading, chatName, avatar, isCharacterReady, selectedChatIndex } = currentChar;

    if (loading) {
        return (
            <div className="d-flex w-100 flex-grow-1 align-items-center justify-content-center">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }
    if (selectedChatIndex !== null) {
        return (
            <MessageCommentContainer
                message={messages[selectedChatIndex]}
                onBackPress={() => setCharData({ selectedChatIndex: null })}
            />
        );
    }

    const handleShowAd = () => {
        setShowAdModal(true);
        setTimeout(() => {
            setShowAdModal(false);
            setShowRewardAdModal(true);
        }, 5000);
    };

    const handlePrizeSelect = (points) => {
        setShowRewardAdModal(false);
        PointRewardApiInstance.redeemAdvertReward(token, points, currentChar?.character?._id)
        .then(_ => {
            alert(`You have won ${points} Orange points.`);
            dispatch({
                type: "UPDATE",
                payload: { user: { key: "orangePointBalance", value: user?.orangePointBalance + points } }
            });
        }).catch(e => {
            alert(e?.response?.data?.message ?? "Something went wrong.");
        });
    };

    const handleToggleTranslate = (e) => {
            setaAutoTranslate(e.target.checked);
    };

    const handleSelectedSpicyAi = (e) => {
        console.log("handleSelectedSpicyAi: ",e.target.checked);
    };

    const handleToggleRolePlay = (e) => {
        setRolePlay(e.target.checked);
        console.log("rolePlay: ",rolePlay); 
};

    const handleSpicyModeChange = () => {
        const newPreference = user?.spicyChatEnabled ? false : true;
        authAPIInstance
            .updateSpicyChatPreference(newPreference)
            .then((_) => {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "spicyChatEnabled",
                            value: newPreference
                        }
                    }
                });
            })
            .catch(console.error);
    };

    const handleAttachImageClick = () => {
        attachmentFileRef.current.click();
    }

    const handleImageChange = (e) => {
        if (selectedUserId && chatId) {
            const file = e.target.files[0];

            const formData = new FormData();
            formData.append("recipient_id", selectedUserId);
            formData.append("characterId", chatId);
            formData.append("file", file);

            ClientCharAPIInstance.sendImageMessageAsCharacter(formData)
                .then((res) => {
                    console.log("sendImageMessageAsCharacter: ", res);
                    setMessages((prev) => {
                        let temp = [...prev];
                        temp.unshift({
                            ...res?.data?.data,
                            messageContent: res?.data?.data?.messageContent?.encrypted_text ?? "",
                            originalMessage: ""
                        });
                        return [...temp];
                    });
                })
                .catch(error => {

                    console.log("sendImageMessageAsCharacter: ", error);
                });
        }
    };

    const hanldeSubscribeToCharacter = () => {
        navigate(`/character/${chatId}`);
    };

    return (
        <>
            <div className="chat-header d-flex justify-content-between align-items-center border-bottom pb-4 pb-lg-3 p-1">
                <div className="d-flex align-items-center px-4 pt-4 pt-lg-3 ">
                    <Avatar
                        className="br-50"
                        onClick={handleAvatarClick}
                        name={chatName}
                        avatarUrl={avatar !== "" && (avatar?.includes("http") ? avatar : getAiCharacterImageUrl(avatar))}
                    />
                    <div className="ms-3">
                        <h6 className="mb-0">{chatName}</h6>
                        <div className="d-flex align-items-center">
                            <StatusBadge status={status} />
                            <small className="text-muted ms-1">{status}</small>
                        </div>
                    </div>
                </div>
               {chatDisabledSubscription && <Button onClick={hanldeSubscribeToCharacter}>
                    Subscribe To Character
                </Button>}
                <Form.Switch
                    className="chat-toggle-btn"
                    label={`Spicy ${user?.spicyChatEnabled ? "O" : "X"}`}
                    defaultChecked={user?.spicyChatEnabled ? true : false}
                    onChange={handleSpicyModeChange}
                />
                <div style={{ cursor: "pointer", marginLeft: "20px" }} className="d-flex flex-row gap-5 align-items-center">
                    <span onClick={handleShowAd}>{user?.orangePointBalance} OP</span>
                    <Dropdown style={{ width: "fit-content" }}>
                        <Dropdown.Toggle style={{ backgroundColor: "#272727" }} variant="success" id="dropdown-basic" />
                        <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item onClick={toggleSelectSpicyAiPopup}>Select Ai Model</Dropdown.Item>
                            <Dropdown.Item onClick={handleUpdateSettingsClick}>Update Params</Dropdown.Item>
                            <Dropdown.Item onClick={handleClickPublishChat}>
                                {!isChatPublic ? "Publish Chat" : "Make this Chat private."}
                            </Dropdown.Item>

                            <Dropdown.Item onClick={toggleTranslateModal}>
                            Auto Translate
                            </Dropdown.Item>

                            <Dropdown.Item onClick={toggleRolePlayModal}>
                            Role Play Mode
                            </Dropdown.Item>

                            <Dropdown.Item onClick={handleDeleteChat}>
                            Delete Chat
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <a className="py-2 d-lg-none chatlist-toggle me-3" href="#!">
                <Button
                        variant="transparent"
                        style={{ background: 'rgba(0,0,0,0.5)', borderRadius: '12px'}}
                        className="ms-2 d-flex align-items-center py-1 px"
                        onClick={toggleDrawer}
                >
                <i class="fa-solid fa-chevron-left"></i>
                <p className="mb-0 ms-2">Go back</p>
                </Button>
                {/* <span className="button__badge">1</span> */}
            </a>
            <ul style={currentChar?.character?.coverUrl ? {backgroundSize: "cover", backgroundImage:`url(${getAiCharacterImageUrl(currentChar?.character?.coverUrl)})`}: {}} className="chat-history list-unstyled mb-0 flex-grow-1 px-5 px-lg-4 py-4 py-lg-3  ">
                {renderChats()}
                {charReplying && (
                    <div className="color-defult-white">{currentChar?.character?.characterName || "Bot"} is replying...</div>
                )}
                <div ref={bottomRef} />
            </ul>
                    <AutoTranslateModal 
                    setTranslateModal={setTranslateModal} 
                    translateModal={translateModal} 
                    autoTranslate={autoTranslate} 
                    handleToggleTranslate={handleToggleTranslate} 
                    customStyles={customStyles} />
            
                    <SelectSpicyAiModal 
                    setSelectSpicyAiPopup = {setSelectSpicyAiPopup}
                    selectSpicyAiPopup = {selectSpicyAiPopup}
                    setSpicyAiModels={setSpicyAiModels}
                    spicyAiModels = {spicyAiModels}
                    ws={ws}
                    customStyles={customStyles}
                    selectedSpicyAiModel={selectedSpicyAiModel}
                    setSelectedSpicyAiModel={setSelectedSpicyAiModel}
                    />

                <Modal
                setIsOpen={setRolePlayModal}
                modalIsOpen={rolePlayModal}
                showClose={true}
                styles={customStyles}
                body={
                    <div className="px-2 d-flex flex-column gap-3">
                        <strong>
                            <span>{`Do you want to toggle role play?`}</span>
                        </strong>
                       <Form.Switch
                            label={`Role Play Mode ${rolePlay ? "On" : "Off"}`}
                            defaultChecked={rolePlay}
                            onChange={handleToggleRolePlay} 
                        />
                    </div>
                }
                />
            <Modal
                setIsOpen={setShowAdModal}
                modalIsOpen={showAdModal}
                showClose={true}
                preventEscClose={true}
                styles={customStyles}
                body={<GoogleAd />}
            />
            <Modal
                styles={customStyles}
                setIsOpen={setShowRewardAdModal}
                modalIsOpen={showRewardAdModal}
                body={<RewardAdBox onSelectBox={handlePrizeSelect} />}
            />
            <div className="position-relative bottom-0 border-top d-flex gap-2 py-2 px-2 text-input-container">
                <Button
                    className="chat-menu-icon-btn"
                    variant="transparent"
                    onClick={handleAttachImageClick}
                    disabled={chatDisabledSubscription}>
                    <i className="fa-solid fa-plus chat-menu-icon"></i>
                </Button>
                <div className="chat-input-container">
                    <textarea
                        className="chat-form-control"
                        placeholder={"Send Message"}
                        value={currentChar.message}
                        onChange={handleOnChangeTextArea}
                        disabled={chatDisabledSubscription}
                    ></textarea>
                    <Button
                        disabled={currentChar.message === ""}
                        className="gradient-chat-menu-icon-btn"
                        variant="transparent"
                        onClick={handleSendMessage}>
                        <i className="fa-solid fa-paper-plane gradient-chat-menu-icon"></i>
                    </Button>
                </div>
            </div>
            <Modal
                modalIsOpen={askPublishChat.showModal}
                setIsOpen={toggleAskPublicModal}
                styles={customStyles}
                body={
                    <PublishChatBody
                        toggleAskPublicModal={toggleAskPublicModal}
                        respondAskPublicModal={respondAskPublicModal}
                        isChatPublic={isChatPublic}
                    />
                }
            />
        </>
    );
}

const MessageCommentContainer = React.memo(({ message, onBackPress }) => {
    return (
        <div className="px-4 py-2">
            <Button className="my-2" onClick={onBackPress}>
                Back
            </Button>
            <MessageComments message={message} allowComments />
        </div>
    );
});

export default React.memo(ClientChatBox);
